export default function blockChainData(chainId) {
  chainId = parseInt(chainId);
  // Supported Chain ID
  const ethereumChainID = parseInt(process.env.NEXT_PUBLIC_CHAIN_ID);
  const polygonChainID = parseInt(process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID);
  // Current Blockchain
  const currentBlockChain = (chainId === polygonChainID) ? 'polygon' : 'ethereum';
  // Current Network
  const currentNetwork =  (currentBlockChain === 'ethereum') ? 'Ethereum' : 'Polygon';
  // Block Chain Details
  const blockChainURL = (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_ETH_JSONRPC_URL : process.env.NEXT_PUBLIC_POLYGON_ETH_JSONRPC_URL;
  const blockChainID = (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_CHAIN_ID : process.env.NEXT_PUBLIC_POLYGON_CHAIN_ID;
  const blockExplorer  = (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_BLOCK_EXPLORER : process.env.NEXT_PUBLIC_POLYGON_BLOCK_EXPLORER;
  const contractAddress = (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_CONTRACT_ADDRESS_KEY : process.env.NEXT_PUBLIC_POLYGON_CONTRACT_ADDRESS_KEY;
  const contractABIKey =  (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_CONTRACT_ABI_KEY : process.env.NEXT_PUBLIC_POLYGON_CONTRACT_ABI_KEY;

  const contractAddressMultiple = (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_CONTRACT_MULTIPLE_ADDRESS_KEY_ETH : process.env.NEXT_PUBLIC_CONTRACT_MULTIPLE_ADDRESS_KEY;
  const contractABIKeyMultiple =  (currentBlockChain === 'ethereum') ? process.env.NEXT_PUBLIC_CONTRACT_MULTIPLE_ABI_KEY_ETH : process.env.NEXT_PUBLIC_CONTRACT_MULTIPLE_ABI_KEY;

  // Response
  let response = {
    ethereumChainID : ethereumChainID,
    polygonChainID : polygonChainID,  
    currentBlockChain : currentBlockChain,
    currentNetwork : currentNetwork,
    blockChainURL : blockChainURL,
    blockChainID : blockChainID,
    blockExplorer : blockExplorer,
    contractAddress : contractAddress,
    contractABIKey : contractABIKey,
    contractAddressMultiple: contractAddressMultiple,
    contractABIKeyMultiple: contractABIKeyMultiple
  }
  return response;
};