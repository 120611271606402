import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';

const PurchaseTooltipGems = (props) => {
  const [gems,setGems] = useState(25);
  useEffect(() => {
    if(props?.price){
      let price = props?.price ? props?.price : 0;
      let addGems = parseInt(price) > 50 ? parseFloat(price) - 50 : 0;
      let finalGems = parseFloat(addGems) + parseFloat(50);
      setGems(finalGems);
    }
  }, [props])
  return (
    <div className="purchase_tooltip">
      <div className={`${styles.purchase_tooltip_gemsbox}`}>
        <div className={`${styles.purchase_tooltip_gems_img}`}>
          <Image src="/assets/gemstarlogo.svg" alt="Logo" layout='fill' />
        </div>

        <div className={`${styles.purchase_tooltip_gems_details}`}>
          <div className={`${styles.purchase_tooltip_gems_title_separates}`}>
            <h2 className={`${styles.purchase_tooltip_gemstitles}`}>
              Earn Gems -
              <span className={`${styles.purchase_tooltip_gemsvalues}`}>{gems}</span>
            </h2>
            <div className="tooltip_gems_small_img">
              <Image src="/assets/gemssimple.svg" alt="Logo" layout='fill' />
            </div>
          </div>
          <p className={`${styles.purchase_tooltip_gemsdetails}`}>
            {props?.page_type === 4 ? (
             "After Purchasing this NFT"
            ) : (
              <>
                {`Listing NFT as  ${
                  props?.page_type === 1
                    ? 'a nft'
                    : props?.page_type === 2
                    ? 'a giveaway'
                    : 'an artwork'
                }`}
              </>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseTooltipGems;
